// ** Icon imports
import SchoolIcon from '@mui/icons-material/School';
import { ReactComponent as DashBoardIcon } from '../../assets/dashboard.svg';
import { ReactComponent as UsersIcon } from '../../assets/Users.svg';
import { ReactComponent as PromptLibraryIcon } from '../../assets/PromptLibrary.svg'
import { ReactComponent as PromptCategoriesIcon } from '../../assets/PromptCategories.svg'
import { ReactComponent as ReportIcon } from '../../assets/reporting.svg'
import HelpIcon from '@mui/icons-material/Help';
import ArticleIcon from '@mui/icons-material/Article';
import TaskIcon from '@mui/icons-material/Task'
import LockIcon from '@mui/icons-material/Lock'
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';

const navigation = () => {
  return [
    {
      title: 'Dashboard',
      icon: DashBoardIcon,
      path: '/dashboard'
    },
    // {
    //   sectionTitle: "Website Settings",
    // },
    // {
    //   title: 'Website',
    //   icon: PublicIcon,
    //   children: [
    //     {
    //       title: 'Blog',
    //       icon: ArticleIcon,
    //       path: '/blog'
    //     },
    //   ]
    // },
    {
      title: 'Users',
      icon: UsersIcon,
      path: '/users'
    },
    {
      title: 'Prompts Library',
      icon: PromptLibraryIcon,
      path: '/prompt-library'
    },
    {
      title: 'Prompt Categories',
      icon: PromptCategoriesIcon,
      path: '/prompt-categories'
    },
    {
      title: 'Reporting',
      icon: ReportIcon,
      path: '/reporting'
    },
    // {
    //   title: 'Prompt Generator',
    //   icon: PromptCategoriesIcon,
    //   path: '/prompt-generator'
    // },
    {
      title: 'Subscription Plans',
      icon: SchoolIcon,
      path: '/subscription-plans'
    },
    // {
    //   title: 'knowledge Base',
    //   icon: SchoolIcon,
    //   path: '/knowledgebase'
    // },
    {
      title: 'FAQS',
      icon: HelpIcon,
      path: '/faqs'
    },
    {
      title: 'Blog',
      icon: ArticleIcon,
      path: '/blog'
    },
    {
      title: 'Privacy Policy',
      icon: LockIcon,
      path: '/privacy-policy'
    },
    {
      title: 'Terms and Conditions',
      icon: TaskIcon,
      path: '/terms-and-conditions'
    },
    {
      title: 'Contact Us',
      icon: PermContactCalendarIcon,
      path: '/contact-us'
    },
  ]
}

export default navigation
