// ** MUI Imports
import Box from '@mui/material/Box'

// ** Components
import Autocomplete from 'src/layouts/components/Autocomplete'
import ModeToggler from 'src/@core/layouts/components/shared-components/ModeToggler'
import UserDropdown from 'src/@core/layouts/components/shared-components/UserDropdown'
import LanguageDropdown from 'src/@core/layouts/components/shared-components/LanguageDropdown'
import NotificationDropdown from 'src/@core/layouts/components/shared-components/NotificationDropdown'

const AppBarContent = props => {
  // ** Props
  const { hidden, settings, saveSettings } = props

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {/* <Autocomplete hidden={hidden} settings={settings} /> */}
      {/* <LanguageDropdown settings={settings} saveSettings={saveSettings} /> */}
      <UserDropdown settings={settings} />
      <ModeToggler settings={settings} saveSettings={saveSettings} />
      {/* <NotificationDropdown settings={settings} /> */}
    </Box>
  )
}

export default AppBarContent
