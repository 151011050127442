import React, { Suspense } from "react";
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import FallbackSpinner from "./@core/components/spinner";
import AuthGuard from "src/@core/components/auth/AuthGuard";
import UserLayout from "./layouts/UserLayout";
import BlankLayout from "./@core/layouts/BlankLayout";
import AclGuard from "./@core/components/auth/AclGuard";
import GuestGuard from "./@core/components/auth/GuestGuard";
import { defaultACLObj } from "./configs/acl";
import AuthLayout from "./layouts/AuthLayout";
//website routes
const HomePage = React.lazy(() => import("./pages/home"));
const DashboardPage = React.lazy(() => import("./pages/dashboard"));
const LoginPage = React.lazy(() => import("./pages/login"));
const ForgotPassword = React.lazy(() => import("./pages/login/forgotpassword"));
const UserPage = React.lazy(() => import("./pages/users"));
const KnowledgeBasePage = React.lazy(() => import('./pages/knowledgebase'))
const SubscriptionPlanPage = React.lazy(() => import('./pages/subscriptionplan'))
const PromptLibraryPage = React.lazy(() => import('./pages/promptlibrary'))
const PromptGenerator = React.lazy(() => import('./pages/promptsgenerator'))
const PromptGeneratorEdit = React.lazy(() => import('./pages/PromptEdit'))
const CreatePromptCategories = React.lazy(() => import('./pages/createprompt'))
const ReportingPage = React.lazy(() => import('./pages/reporting'))
const FaqsPage = React.lazy(() => import('./pages/faqs'))
const BlogPage = React.lazy(() => import('./pages/blog'))
const PrivacyPolicyPage = React.lazy(() => import('./pages/privacy-policy'))
const TermsandConditionPage = React.lazy(() => import('./pages/terms-and-condition'))
const ContactUsPage = React.lazy(() => import('./pages/contactUs'))

const Page401 = React.lazy(() => import("./pages/401"));
const Page404 = React.lazy(() => import("./pages/404"));

const Guard = ({ children, authGuard, guestGuard }) => {
  if (guestGuard) {
    return <GuestGuard fallback={<FallbackSpinner />}>{children}</GuestGuard>;
  } else if (!guestGuard && !authGuard) {
    return <>{children}</>;
  } else {
    return <AuthGuard fallback={<FallbackSpinner />}>{children}</AuthGuard>;
  }
};

function App() {
  const aclAbilities = defaultACLObj;

  return (
    <Suspense fallback={<FallbackSpinner />}>
      <AclGuard aclAbilities={aclAbilities}>
        <Routes>
          <Route
            element={
              <BlankLayout>
                <Outlet />
              </BlankLayout>
            }
          >
            <Route path="/401" element={<Page401 />} />
            <Route path="/404" element={<Page404 />} />
            <Route
              element={
                <AuthLayout>
                  <Outlet />
                </AuthLayout>
              }
            >
              <Route
                element={
                  <Guard guestGuard>
                    <Outlet />
                  </Guard>
                }
              >
                <Route path="/login" element={<LoginPage />}></Route>
                <Route path="/forgot-password" element={<ForgotPassword />} />
              </Route>
            </Route>
          </Route>
          <Route element={<UserLayout> <Outlet /> </UserLayout>}>
            <Route element={<Guard authGuard><Outlet /></Guard>}>
              <Route path="" element={<HomePage />} />
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path='/users' element={<UserPage />} />
              <Route path='/knowledgebase' element={<KnowledgeBasePage />} />
              <Route path='/prompt-library' element={<PromptLibraryPage />} />
              <Route path="/prompt-library/promptgenrator-edit/:id" element={<PromptGeneratorEdit />} />
              <Route path='/prompt-generator' element={<PromptGenerator />} />
              <Route path='/prompt-categories' element={<CreatePromptCategories />} />
              <Route path='/subscription-plans' element={< SubscriptionPlanPage />} />
              <Route path='/reporting' element={<ReportingPage />} />
              <Route path='/faqs' element={<FaqsPage />} />
              <Route path='/blog' element={<BlogPage />} />
              <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
              <Route path='/terms-and-conditions' element={<TermsandConditionPage />} />
              <Route path='/contact-us' element={<ContactUsPage />} />
            </Route>
          </Route>
          {/* If no route found redirect it to --> /404 */}
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </AclGuard>
    </Suspense>
  );
}

export default App;
