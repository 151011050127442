// ** Icon imports
import HomeIcon from "mdi-material-ui/Home";
import CogIcon from "mdi-material-ui/Cog";
import { BiSolidCoupon } from "react-icons/bi";

const navigation = () => {
  return [
    {
      title: "Dashboard",
      icon: HomeIcon,
      path: "/",
    },
  ];
};

export default navigation;
